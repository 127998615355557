/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
// import GravityForm from 'components/GravityForm'
// import ParseContent from 'components/shared/ParseContent'
import HeroLanding from 'components/elements/HeroLanding'
import {
  Service
} from 'components/elements'
// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'


const PageTemplate = ({
  data: {
    page: { title, path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroLanding
        fields={acf.banner}
      />

      <a id="testsetup" />

      <div className="py-lg-5 mt-5">
        <div className="pt-lg-5">
          <Service
            fields={acf.blok_1}
            direction="left"
            backgroundTitle={acf.blok_1.background_title}
            gravityFormId={acf.blok_1.gravity_form_id}
          />

          <a id="quickscan" />
          
        </div>
      </div>


      <div className="py-lg-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        blok_1 {
          background_title
          gravity_form_id
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
